import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import Layout from "../helper/layout"
import styled, { keyframes } from "styled-components"
import NavigationContainer from "../shared/Navigation/NavigationDrupal"
import LogoTop from "../shared/LogoTop/LogoTop"
import Btn from "../shared/Button/Button"
import logo from "../white-co-images/404.svg"
import color from "../style/color"
import Footer from "../shared/Footer/FooterDrupal"
import curve from "../white-co-images/line.svg"
import {
  yellowBackground,
  lilacBackground,
  blueBackground,
  pinkBackground,
} from "../components/BackgroundGradients/BackgroundGradient"
const smile = keyframes`
from { opacity: 1; stroke-dashoffset: 778; } to { opacity: 1; stroke-dashoffset: 0; }
`

const StyledLogo = styled.svg`
  width: 100%;
  max-width: 441px;
  margin: -42px auto 50px;
  backface-visibility: hidden;

  @media (max-width: 1023px) {
    margin-bottom: 35px;
  }
    & path {
      opacity: 0;
    }
   .line {
    opacity: 1;
    transform-origin:	50% 0;
    transform:scale(-1, 1);
    stroke-dasharray: 778;
    stroke-dashoffset: 778;
    animation: ${smile} 1.6s ease-out forwards;
    }
    @media (max-width: 1023px) {
      margin-bottom: 35px;
    }
  }
`
const line = (
  <StyledLogo viewBox="0 0 495 249" preserveAspectRatio="xMinYMin meet">
    <path
      className="line"
      fill="none"
      stroke="#FFF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M493.4 0 L493.4 1.5 Q493.4 103.45 421.3 175.4 349.35 247.45 247.4 247.45 145.45 247.45 73.4 175.4 1.4 103.45 1.4 1.5 L1.4 0"
    />
  </StyledLogo>
)

const NotFound = () => {
  const [menuIsOpen, toggleMenu] = useState(false)

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wytes | Page Not Found</title>
        <meta name="description" content={"Page not found"} />
        {/* {process.env.GATSBY_AWS_BRANCH !== "uat" && (
          <meta name="robots" content="noindex, nofollow" />
        )} */}
        {/* <meta name="robots" content="noindex, nofollow" /> */}
        <link rel="canonical" href={`https://www.wytes.co.uk/404`} />

        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          // rel="stylesheet"
          rel="preload"
        />

        <link
          href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          // rel="stylesheet"
          rel="preload"
        />

        <script type="application/ld+json">
          {`
          {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "Wytes",
          "url": "https://www.wytes.co.uk",
          "address": "13 Station Crescent, Westcombe Park, SE3 7EQ",
          "sameAs": [
          "https://www.facebook.com/whiteandcodental/",
          "https://www.instagram.com/whiteandcodental/"
        ]
      }
      `}
        </script>
      </Helmet>
      <LogoTop />
      <NavigationContainer
        slug={"/404"}
        toggleMenu={toggleMenu}
        menuIsOpen={menuIsOpen}
      />
      <ComponentContainer hideContent={menuIsOpen}>
        <Container>
          <img src={logo} alt="Wytes logo" />
          <h3>Oops! The page you were looking for doesn't exist.</h3>
          <p>
            The page requested couldn't be found: this could be a spelling error
            or a removed page.
          </p>
          <Btn href={process.env.BASE} text="Go to homepage" tertiary />
        </Container>
        <Footer />
      </ComponentContainer>
    </Layout>
  )
}

const Container = styled.div`
  background: ${yellowBackground};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid white;
  padding: 13.6rem 0 14rem;
  position: relative;

  & img {
    width: 28rem;
    margin-bottom: 30px;
  }

  & h3 {
    margin-bottom: 3rem;
    text-align: center;
  }

  & p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 3.5rem;
    max-width: 56rem;
    text-align: center;
  }

  &:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    background: ${color.brand.blue};
    width: 100%;
    height: 100vh;
    z-index: -1;
  }

  @media (max-width: 1023px) {
    padding: 75px 25px;

    & p {
      font-size: 16px;
      line-height: 26px;
    }
  }
`

const ComponentContainer = styled.div<ComponentContainerProps>`
  ${props => props.hideContent && `visibility: hidden`};
`

export default NotFound
